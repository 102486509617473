<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col :cols="12" sm="10" offset-sm="1" md="8" offset-md="2" lg="6" offset-lg="3">
          <v-toolbar>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text>
                  {{ type === 'C' ? 'College' : 'Foundation' }} Board Minutes
                  <v-icon right>fal fa-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :disabled="!collegeAccess || type === 'C'" @click="type = 'C'">
                  <v-list-item-title>College Board Minutes</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!foundationAccess || type === 'F'" @click="type = 'F'">
                  <v-list-item-title>Foundation Board Minutes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <group-card :files="files" :folder-id="folderId" :is-card-editable="false" label="Minutes" @update="(obj) => update(obj)" @add="(obj) => add(obj)"></group-card>
        </v-col>
        <v-col :cols="12">
          <v-card>
            <v-toolbar>
              <v-toolbar-title>Google Drive Folder</v-toolbar-title>
              <span style="margin:5px 0 0 5px">{{ unlinkedFiles.length }} unlinked files</span>
              <v-spacer></v-spacer>
              <v-btn v-if="folderId" :href="'https://drive.google.com/drive/u/0/folders/' + folderId" target="_blank" icon>
                <v-icon>fal fa-external-link</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list>
              <v-list-item v-for="({ id, mimeType, name }, fileIndex) in unlinkedFiles" :key="'ulfile-' + fileIndex">
                <v-list-item-avatar>
                  <v-icon v-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ name }}</v-list-item-title>
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="add({ id, name, mimeType }, fileIndex)">
                        <v-icon>fal fa-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Add to Minutes</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { watch, ref } from '@vue/composition-api'

export default {
  components: {
    GroupCard: () => import('./fileGroupCard')
  },
  props: {
    collegeAccess: {
      type: Boolean,
      default: false
    },
    foundationAccess: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const service = root.$feathers.service('board/files')
    const files = ref([])

    const type = ref(props.collegeAccess ? 'C' : 'F')

    const serviceId = ref('')
    const folderId = ref('')
    const unlinkedFiles = ref([])

    watch(type, async () => {
      const { data } = await service.find({ query: { year: 'Minutes', type: type.value } })
      if (data.length === 0) {
        const { _id } = await service.create({
          year: 'Minutes',
          type: type.value,
          col1: [{
            label: 'Minutes',
            files: []
          }]
        })
        serviceId.value = _id
        files.value = []
      } else {
        serviceId.value = data[0]._id
        files.value = data[0].col1[0].files
      }
      unlinkedFiles.value = []
      const { folder, files: driveFiles } = await root.$feathers.service('board/drive-file').find({ query: { term: 'Minutes', type: type.value } })
      folderId.value = folder
      for (const { id, mimeType, name } of driveFiles) {
        if (files.value.filter(({ path }) => path === id).length === 0) {
          unlinkedFiles.value.push({ id, mimeType, name })
        }
      }
    })

    function update (obj) {
      files.value = obj.files
      service.patch(serviceId.value, { 'col1.0.files': files.value })
    }

    async function add ({ id, name, mimeType }, fileIndex) {
      let regex = /\.(pdf|xlsx|zip)$/
      if (regex.test(name)) {
        name = name.substr(0, name.lastIndexOf('.'))
      }
      const { files: driveFiles } = await service.patch(serviceId.value, { $push: { 'col1.0.files': { $each: [{ name, mimeType, path: id }], $position: 0 } } })
      for (const { id, mimeType, name } of driveFiles) {
        if (files.value.filter(({ path }) => path === id).length === 0) {
          unlinkedFiles.value.push({ id, mimeType, name })
        }
      }
      if (fileIndex != null) {
        unlinkedFiles.value.splice(fileIndex, 1)
      }
    }

    return {
      files,
      type,
      folderId,
      unlinkedFiles,
      update,
      add
    }
  }
}
</script>
