var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"sm":"10","offset-sm":"1","md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[_c('v-toolbar',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.type === 'C' ? 'College' : 'Foundation')+" Board Minutes "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.collegeAccess || _vm.type === 'C'},on:{"click":function($event){_vm.type = 'C'}}},[_c('v-list-item-title',[_vm._v("College Board Minutes")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.foundationAccess || _vm.type === 'F'},on:{"click":function($event){_vm.type = 'F'}}},[_c('v-list-item-title',[_vm._v("Foundation Board Minutes")])],1)],1)],1)],1),_c('group-card',{attrs:{"files":_vm.files,"folder-id":_vm.folderId,"is-card-editable":false,"label":"Minutes"},on:{"update":function (obj) { return _vm.update(obj); },"add":function (obj) { return _vm.add(obj); }}})],1),_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Google Drive Folder")]),_c('span',{staticStyle:{"margin":"5px 0 0 5px"}},[_vm._v(_vm._s(_vm.unlinkedFiles.length)+" unlinked files")]),_c('v-spacer'),(_vm.folderId)?_c('v-btn',{attrs:{"href":'https://drive.google.com/drive/u/0/folders/' + _vm.folderId,"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("fal fa-external-link")])],1):_vm._e()],1),_c('v-list',_vm._l((_vm.unlinkedFiles),function(ref,fileIndex){
var id = ref.id;
var mimeType = ref.mimeType;
var name = ref.name;
return _c('v-list-item',{key:'ulfile-' + fileIndex},[_c('v-list-item-avatar',[(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.add({ id: id, name: name, mimeType: mimeType }, fileIndex)}}},on),[_c('v-icon',[_vm._v("fal fa-plus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Add to Minutes")])])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }